@tailwind base;

/* Write your own custom base styles here. */

@tailwind components;

/* Write you own custom component styles here. */

@tailwind utilities;

/* Custom fonts. */

@layer base {
  @font-face {
    font-family: 'Matter';
    src: url('/fonts/Matter-Regular.woff') format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/fonts/Matter-Medium.woff') format('woff');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/fonts/Matter-Bold.woff') format('woff');
    font-weight: 700;
  }
}

/* Your own custom utilities. */

@media (min-width: 1024px) {
  .blur {
    filter: blur(3px);
    transform: scale(1.03);
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tooltip-container.settings {
  padding: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  border-radius: 0 !important;
  border: none !important;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.popup-overlay--base {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  outline: none;
  transition: opacity 200ms ease-in-out;
  z-index: 1000;
}

.popup-overlay--base.dark {
  background-color: rgba(0, 0, 0, 0.3);
}

.popup-overlay--after-open {
  opacity: 1;
}

.popup-overlay--before-close {
  opacity: 0;
}

.popup-content--base {
  @apply transform-gpu;
  @apply translate-y-full lg:-translate-x-1/2;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: initial;
}

.popup-content--after-open {
  @apply translate-y-0 lg:-translate-y-1/2;
}

.popup-content--before-close {
  @apply translate-y-full;
}

.sidebar-content--base {
  @apply transform-gpu;
  @apply translate-x-full;
  transition-property: transform;
  transition-timing-function: ease-out;
  transition-delay: initial;
}

.sidebar-content--after-open {
  @apply translate-x-0;
}

.sidebar-content--before-close {
  @apply translate-x-full;
}

.PhoneInput.user-phone {
  margin-top: 1rem;
}

.PhoneInput.user-phone input {
  /* font-weight: 500; */
  background-color: rgba(0, 0, 0, 0);
  letter-spacing: 0.025em;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-right: 2.1875rem;
}

.PhoneInput.user-phone.center input {
  text-align: center;
}

.PhoneInput input:focus {
  outline: none;
}

.truncate-overflow {
  --lh: 1.25rem;
  --max-lines: 2;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  text-overflow: ellipsis;
}
